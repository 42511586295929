import { PlaceholderCard, TextLink } from "@app/design-system";
import getConfig from "next/config";
import PageLayout from "../components/layout/PageLayout/PageLayout";
import type { NextPageWithLayout } from "./_app.page";

const { publicRuntimeConfig } = getConfig();
const { COP_ENABLED } = publicRuntimeConfig;

const NotFoundPage: NextPageWithLayout = () => {
  return (
    <PlaceholderCard
      cta={
        <TextLink href="/" variant="textWeak">
          Return to {COP_ENABLED ? "State View" : "State Map"}
        </TextLink>
      }
      status="error"
      title="We can't find this page"
      subtitle="Looks like you've taken a wrong turn!"
    />
  );
};

NotFoundPage.getLayout = (page) => {
  return <PageLayout variant="medium">{page}</PageLayout>;
};

export default NotFoundPage;
